@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap');
@tailwind base;

* {
    scrollbar-width: thin;
    scrollbar-color: rebeccapurple cornflowerblue;
  }
  

  *::-webkit-scrollbar {
    width: 15px;
  }
  
  *::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color:rebeccapurple;
    border-radius: 14px;
  }
@tailwind components;
@tailwind utilities;


/* Skiils */
.skills {
  @apply text-gray-400 text-[12px] lg:text-[1rem];
}

.skillsTitle {
  @apply flex flex-wrap justify-start items-center gap-1 lg:gap-2 mb-[10px];
}

.skillsDesc {
  @apply text-[#33CCCC] text-[14px] lg:text-[18px];
}

.profficientSkills {
  @apply text-gray-400 ml-4 text-[12px] lg:text-[18px];
}

.fimilarSkills {
  @apply text-gray-400 text-[12px] lg:text-[1rem];
}


/* Home */

.HomeIcon {
  @apply  rounded text-blue-700 hover:bg-[#F2f4F8] hover:rounded-lg hover:border hover:border-indigo-700;
}